<template>
  <div>
    <GenericForm
      title="material"
      previousRoute="materialTemporario"
      editRoute="materialTemporarioEditar"
      etiquetaRoute="materialTemporario"
      :model="model"
      route="material"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_material"
      :permissionsToWrite="['rw_material']"
      :onlyVisualize="onlyVisualize"
      :permissionsToDelete="['rd_material']"
      :loadOnSave="true"
      :isMaterial="true"
    >
      <template v-slot="{ validateState, isBusy }">
        <div v-if="tab === 'material'">
          <div class="button-header">
            <div>
              <Button
                @click="openModalFoto"
                variant="grayscale"
                v-if="hasFotos"
                :text="fotosButtonText"
                icon="camera"
              />
            </div>
          </div>
          <b-row align-v="center">
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Tipo de material*" label-for="tipoMaterial">
                <GenericSelect
                  name="tipoMaterial"
                  @input="changeFieldsRender"
                  labelKey="nome"
                  :value="idTipoMaterial"
                  :customFilters="TiposFilter"
                  route="tipoMaterial"
                  :disabled="isBusy || editing"
                  v-validate="{ required: !editing }"
                  :state="validateState('tipoMaterial')"
                  :disabledFirstOption="true"
                ></GenericSelect>
              </b-form-group>
            </b-col>
            <b-col
              sm="12" md="6" lg="6" xl="6"
              v-if="campoIsEditable('id_fornecedor')"
            >
              <b-form-group label="Fornecedor" label-for="fornecedor">
                <GenericSelect
                  name="fornecedor"
                  ref="fornecedor"
                  labelKey="nome"
                  key="fornecedor"
                  v-model="model.id_fornecedor"
                  @input="handleFornecedorChanged"
                  route="fornecedor"
                  :disabled="isBusy || onlyVisualize"
                  v-validate="{
                    required: camposMaterial.id_fornecedor.necessario,
                  }"
                  :state="validateState('fornecedor')"
                ></GenericSelect>
              </b-form-group>
            </b-col>
            <b-col
              sm="12" md="6" lg="6" xl="6"
              v-if="!model.cadastro_lote || editing"
            >
              <b-form-group label="Descrição*" label-for="descricao">
                <InputWithSuggestions
                  route="material/materiaisPorDescricao"
                  propertyName="descricao"
                  id="descricao"
                  name="descricao"
                  v-model="model.descricao"
                  v-validate="{required: true }"
                  :state="validateState('descricao')"
                  item-key-identifier="id_material"
                  item-content-identifier="descricao"
                  :min-search-length="3"
                  :max-item-length="30"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12" md="6" lg="6" xl="6"
              v-if="!model.cadastro_lote || editing"
            >
              <b-form-group label="Patrimônio" label-for="patrimonio">
                <b-form-input
                  v-model="model.patrimonio"
                  v-validate="{required: false }"
                  name="patrimonio"
                  id="patrimonio"
                  :state="validateState('patrimonio')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="Object.keys(camposMaterial).length">
            <b-col
              sm="12" md="6" lg="6" xl="6"
              v-if="campoIsEditable('id_fornecedor')"
            >
              <b-form-group
                v-if="!campoIsEditable('hidden')"
                label="Aviso*" label-for="aviso"
              >
                <InputSearch
                  ref="inputSearch"
                  name="aviso"
                  v-validate="{ required: true }"
                  :state="validateState('aviso')"
                  placeholder="Digite o aviso e clique na lupa para buscar os dados"
                  :route="`mapaCirurgico/${model.aviso}`"
                  :wantsClean="false"
                  :value="model.aviso"
                  @update="getAvisoInfo"
                  @input="updateAviso"
                  @isBusy="updateIsBusy"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12" md="6" lg="6" xl="6"
            >
              <b-form-group
                v-if="!campoIsEditable('hidden')"
                label="Unidade*" label-for="unidade"
              >
                <GenericSelect
                  name="unidade"
                  labelKey="nome"
                  v-model="model.procedimento.id_unidade_destino"
                  key="unidade"
                  route="unidade"
                  :disabled="isBusy || onlyVisualize"
                  v-validate="{ required: true }"
                  :state="validateState('unidade')"
                  @input="inputUnidade"
                ></GenericSelect>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="Object.keys(camposMaterial).length
            && !campoIsEditable('hidden')"
          >
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Paciente*" label-for="paciente">
                <b-form-input
                  id="paciente"
                  v-model="model.procedimento.paciente"
                  autocomplete="off"
                  class="invision-input"
                  name="paciente"
                  :disabled="isBusy || onlyVisualize"
                  v-validate="{ required: true }"
                  :state="validateState('paciente')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Procedimento*" label-for="procedimento">
                <b-form-input
                  id="procedimento"
                  v-model="model.procedimento.procedimento_cirurgico"
                  autocomplete="off"
                  class="invision-input"
                  name="procedimento"
                  :disabled="isBusy || onlyVisualize"
                  v-validate="{ required: true }"
                  :state="validateState('procedimento')"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="Object.keys(camposMaterial).length
            && !campoIsEditable('hidden')"
          >
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Data do procedimento*" label-for="data">
                <b-form-datepicker
                  id="data_procedimento"
                  placeholder="Data do procedimento"
                  v-model="model.procedimento.data_procedimento"
                  name="data_procedimento"
                  locale="pt"
                  size="md"
                  :disabled="isBusy || onlyVisualize"
                  v-validate="{ required: true }"
                  :state="validateState('data_procedimento')"
                />
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group label="Horário do procedimento*" label-for="horario">
                <InputHorario
                  v-model="model.procedimento.horario_procedimento"
                  id="horario_procedimento"
                  name="horario_procedimento"
                  :disabled="isBusy || onlyVisualize"
                  :isBusy="isBusy"
                  v-validate="{ required: true }"
                  :state="validateState('horario_procedimento')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="Object.keys(camposMaterial).length">
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group
                v-if="!campoIsEditable('hidden')"
                label="Especialidade*" label-for="especialidade"
              >
                <b-form-input
                  id="especialidade"
                  v-model="model.procedimento.especialidade"
                  autocomplete="off"
                  class="invision-input"
                  name="especialidade"
                  :disabled="isBusy || onlyVisualize"
                  v-validate="{ required: true }"
                  :state="validateState('especialidade')"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="6" xl="6" v-if="camposMaterial.dupla_checagem">
                <b-form-group
                  :label="camposMaterial.dupla_checagem.descricao"
                  label-for="dupla_checagem"
                >
                  <ToggleSwitch
                    name="dupla_checagem"
                    ref="dupla_checagem"
                    showText
                    :value="model.dupla_checagem"
                    @toggle="(v) => (model.dupla_checagem = v)"
                  />
                </b-form-group>
            </b-col>
            <b-col sm="12" md="6" lg="6" xl="6" v-if="camposMaterial.cor">
                <b-form-group
                  label="Cor"
                  label-for="cor"
                  label-class="float-left"
                >
                  <b-form-input
                    id="cor"
                    name="cor"
                    v-model="model.cor"
                    class="invision-input input-icon"
                    :disabled="isBusy"
                    v-validate="{ required: false }"
                    :state="validateState('cor')"
                  />
                </b-form-group>
            </b-col>
          </b-row>
          <b-row
            v-if="camposMaterial.tipo_processamento"
          >
            <b-col sm="12" md="6" lg="6" xl="6">
              <b-form-group
                label-class="float-left"
                style="border-style: solid;
                  border-color: rgb(215, 211, 211);
                  border-width: 1px;
                  border-radius: 7px;
                  padding: 10px;
                "
                :label=camposMaterial.tipo_processamento.descricao
                :label-for=camposMaterial.tipo_processamento.descricao
              >
                <info-icon
                  v-if="!campoIsEditable('tipo_processamento')"
                  v-b-tooltip.hover.right="
                    'Não é permitido realizar alterações'
                  "
                  class="tooltip-icon"
                />
                <br><br>
                <b-row cols="12">
                  <b-col cols="4">
                    <b-form-group
                      style="margin-left: 8px;"
                      label="Preparo"
                    />
                  </b-col>
                  <b-col cols="8">
                    <GenericMultipleSelect
                      name="tipoLavagem"
                      v-validate="{ required: camposMaterial.tipo_processamento.necessario }"
                      ref="tipoLavagem"
                      labelKey="descricao"
                      :value="tipoLavagem"
                      :state="validateState('tipoLavagem')"
                      route="tipoLavagem"
                      :disabled="!campoIsEditable('tipo_processamento')"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="4">
                    <b-form-group
                      style="margin-left: 8px;"
                      label="Esterilização"
                    />
                  </b-col>
                  <b-col cols="8">
                    <GenericMultipleSelect
                      name="tipoEsterilizacao"
                      v-validate="{ required: camposMaterial.tipo_processamento.necessario }"
                      ref="tipoEsterilizacao"
                      labelKey="nome"
                      :value="tipoEsterilizacao"
                      :state="validateState('tipoEsterilizacao')"
                      route="tipoEsterilizacao"
                      :disabled="!campoIsEditable('tipo_processamento')"
                    />
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- <b-row v-if="campoIsEditable('cadastro_lote') && !model.isMaterialAvulso">
            <b-col sm="12" md="12" lg="12" xl="12">
              <YesOrNoRadio
                name="cadastroEmLote"
                :label="camposMaterial.cadastro_lote.descricao"
                v-model="model.cadastro_lote"
                :disabled="editing || isBusy"
                v-validate="{ required: true }"
                :state="validateState('cadastroEmLote')"
              />
            </b-col>
          </b-row> -->
          <b-row v-if="model.cadastro_lote && !editing">
            <b-col>
              <DescricoesTable
                :lote="model.lote"
                :hasQntItens="
                  camposMaterial.quantidade_itens &&
                  camposMaterial.quantidade_itens.editavel
                "
                :opmeConsignadoLote="true"
                :validateState="validateState"
                @addCaixa="addCaixa"
                @update-lote="updateLote"
                @removeCaixa="removeCaixa"
              />
            </b-col>
          </b-row>
        </div>
      </template>
      <template #buttons="{ isBusy, cancel, exclude, onSubmit, exclusionEnabled }">
        <b-row align-h="between" class="pt-4">
          <b-col>
            <Button
              variant="grayscale"
              @click="cancel"
              :class="editing && exclusionEnabled ? 'rounded left' : ''"
              text="Cancelar"
              :disabled="isBusy"
            />
            <Button
              v-if="editing && !notDeletable"
              variant="grayscale"
              type="text"
              class="rounded right"
              @click="exclude"
              :disabled="isBusy"
              text="Excluir"
            />
          </b-col>
          <b-col class="text-right">
            <Button
              :disabled="isBusy"
              text="Salvar"
              @click="customSubmit(onSubmit)"
            />
          </b-col>
        </b-row>
        <Modal
          id="modalPhoto"
          ref="modalPhoto"
          nome="modalPhoto"
          :title="editing ? 'Fotos do Material' : 'Editar fotos do material'"
          no-close-on-esc
          no-close-on-backdrop
          size="md"
          centered
        >
          <PhotoForm
            :photos="modalPhotos"
            :buttonConfirmar="true"
            downloadRoute="material/downloadFotos"
            :onlyVisualize="model.onlyVisualizeFotos"
            @newPhoto="setImage"
            @confirmar="closeModalFoto"
            :photosName="photosName"
          />
        </Modal>
      </template>
    </GenericForm>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import genericRequest from '@/services/genericRequest';
import unidadeService from '@/services/unidade';

import GenericForm from '@/components/Form/GenericForm';
import loginService from '@/services/login';
import GenericSelect from '@/components/Form/GenericSelect';
// import YesOrNoRadio from '@/components/Utils/YesOrNoRadio';
import InputSearch from '@/components/Form/InputSearch';
import Modal from '@/components/Utils/Modal';
import Button from '@/components/Utils/Button';
import InputHorario from '@/components/Utils/Hour';
import PhotoForm from '@/components/WebCam/PhotoForm';

import DescricoesTable from '../Material/DescricoesTable';
import InputWithSuggestions from '../../components/Form/InputWithSuggestions';
import ToggleSwitch from '../../components/Form/ToggleSwitch';
import GenericMultipleSelect from '@/components/Form/GenericMultipleSelect';

const idsTipoMaterialCadastroRapido = [2, 3, 4];

export default {
  props: {
    tipoMaterial: {
      type: String,
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    GenericForm,
    GenericSelect,
    // YesOrNoRadio,
    InputSearch,
    DescricoesTable,
    Modal,
    PhotoForm,
    Button,
    InputWithSuggestions,
    InputHorario,
    ToggleSwitch,
    GenericMultipleSelect,
  },
  provide() {
    return { parentValidator: this.$validator };
  },

  computed: {
    ...mapState(['currentUnidade', 'configuracao']),
    setorSelectFilters() {
      if (this.model.id_unidade_patrimonio) {
        return { id_unidade: this.model.id_unidade_patrimonio };
      }

      return { id_unidade: 0 };
    },
    isCME() {
      return this.id_setor_CME === this.model.id_setor;
    },
    TiposFilter() {
      if (this.editing) return {};
      // TODO Hardcoded tipo material
      return { onlyTipos: ['OPME Consignado'] };
    },
    onlyVisualize() {
      const permissions = idsTipoMaterialCadastroRapido.includes(this.idTipoMaterial)
        ? loginService.verifyPermissions(['rw_cadastro_rapido'])
        : loginService.verifyPermissions(['re_material']);
      return !permissions && this.editing;
    },
    colsQuantidadeCaixas() {
      if (
        this.camposMaterial.quantidade_itens
        && this.camposMaterial.quantidade_itens.editavel
      ) {
        return '10';
      }
      return '12';
    },
    acceptsAvulso() {
      if (this.model.cadastro_lote && !this.model.id_item_avulso) {
        return false;
      }
      return Boolean(this.camposMaterial.composicao_itens);
    },
    photosName() {
      if (this.model.descricao || this.model.id_material !== -1) {
        return `${this.model.descricao}_ID-${this.model.id_material}`;
      }
      return 'material';
    },
    hasFotos() {
      return !this.model.cadastro_lote;
    },
    fotosButtonText() {
      let text = (this.editing || this.model.onlyVisualizeFotos) ? 'Visualizar fotografias' : 'Inserir fotos';
      if (this.model.fotos.length) text = `${text} (${this.model.fotos.length})`;
      return text;
    },
  },

  async created() {
    await this.getPermisionsExtendedDeleteMaterials();
  },

  data() {
    return {
      tab: 'material',
      model: {
        id_material: this.$route.params.id || -1,
        descricao: '',
        id_unidade_patrimonio: null,
        id_tipo_material: null,
        id_classe_material: null,
        id_tipo_embalagem: null,
        quantidade_itens: null,
        id_medico: null,
        id_fornecedor: null,
        id_fabricante: null,
        id_setor: null,
        forms: [],
        tipo_liberacao: null,
        id_lote_saida_material: null,
        cadastro_lote: true,
        lote: [],
        fotos: [],
        isMaterialAvulso: false,
        codeAvulso: '',
        procedimento: {},
        tipoItem: {},
        onlyVisualizeFotos: false,
        itens: [],
        aviso: '',
        dupla_checagem: false,
        cor: '',
      },
      tipoEsterilizacao: [],
      tipoLavagem: [],
      newItemCode: '',
      loading: false,
      modalPhotos: [],
      currentPageChangeMaterialType: 0,
      oldIdTipoMaterial: null,
      newIdTipoMaterial: null,
      id_setor_CME: null,
      newPropertiesForModel: {},
      idTipoMaterial: null,
      camposMaterial: {},
      cadastro_lote: {
        editavel: true,
        necessario: true,
        padrao: false,
      },
      quantidade_caixas: null,
      descricao_caixas: '',
      quantidade_itens: null,
      tipoItem: {},
      filterTipoItemInox: {
        id_tipo_material: 7,
      },
      aviso: null,
      deletedMaterialsWithCycle: false,
      notDeletable: false,
    };
  },

  watch: {
    async idTipoMaterial(val) {
      if (!this.editing) {
        this.model = {
          ...this.model,
          id_tipo_material: val,
          cadastro_lote: true,
          isMaterialAvulso: false,
          tipo_liberacao: null,
          id_tipo_embalagem: null,
          quantidade_itens: null,
          itens: [],
        };
        await this.updateCamposMaterial(val);
      }
      this.newIdTipoMaterial = val;
      await this.updateTiposProcessamento(val);
    },
    isCME(val) {
      if (val) this.model.tipo_liberacao = 'cme';
    },
    'model.isMaterialAvulso': function isAvulsoChange(v) {
      this.model = {
        ...this.model,
        onlyVisualizeFotos: v,
        descricao: (this.model.itemAvulso && this.model.itemAvulso.descricao) || '',
        quantidade_itens: (
          (this.model.itemAvulso && this.model.itemAvulso.quantidade_pecas)
          || (
            this.camposMaterial.quantidade_itens
            && this.camposMaterial.quantidade_itens.padrao
            && (!this.camposMaterial.quantidade_itens.editavel || !this.editing)
            && this.camposMaterial.quantidade_itens.padrao
          )
          || null
        ),
        fotos: [],
        codeAvulso: (this.model.itemAvulso && this.model.itemAvulso.code) || '',
        id_fabricante: (this.model.itemAvulso && this.model.itemAvulso.id_fabricante) || null,
      };
      if (v && this.tab !== 'material') {
        this.tab = 'material';
      }
    },
    'model.itens': function itensChange(v, oldV) {
      if (oldV.length !== v.length) {
        if (v.length > 1) {
          this.model.isMaterialAvulso = false;
        }
        if (v.length > 0) {
          this.model.quantidade_itens = v.reduce((acum, curr) => acum + +curr.quantidade_pecas, 0);
          this.model.onlyVisualizeFotos = true;
        }
        if (oldV.length && this.model.quantidade_itens && v.length <= 0) {
          this.model.quantidade_itens = null;
        }
      }
    },
  },
  methods: {
    updateIsBusy(isBusy) {
      this.isBusy = isBusy;
    },
    updateAviso(aviso) {
      this.model.aviso = aviso;
      this.model.procedimento.aviso = aviso;
    },
    updateLote(lote) {
      this.model.lote = lote;
    },
    removeCaixa(indexCaixa) {
      this.model.lote.splice(indexCaixa, 1);
    },
    hideModalChangeMaterialType() {
      this.idTipoMaterial = this.model.id_tipo_material;
    },
    confirmChange() {
      this.model.id_tipo_material = this.newIdTipoMaterial;
      this.resetOldRequiredFields();
      this.updateCamposMaterial(this.model.id_tipo_material);
      this.$refs.modalTrocaTipoMaterial.hide();
    },
    resetOldRequiredFields() {
      if (!this.campoIsEditable('id_fornecedor')) {
        this.model.id_unidade_patrimonio = null;
        this.model.id_setor = null;
        this.model.id_medico = null;
        this.model.quantidade_itens = null;
      } else {
        this.model.id_fornecedor = null;
        this.model.id_unidade = null;
      }
    },
    changeFieldsRender(v) {
      this.idTipoMaterial = v;
      this.$validator.pause();
      if (this.editing) {
        this.$refs.modalTrocaTipoMaterial.show();
      }
    },
    async updateModel(model) {
      const {
        lastCiclo, stringZebra, id_fornecedor, fornecedor_nome, medico_nome, ...restModel
      } = model;
      if (fornecedor_nome && !(model.fornecedor && model.fornecedor.nome)) {
        this.model.fornecedor = { id_fornecedor, nome: model.fornecedor_nome };
      }
      this.model = {
        ...restModel,
        id_fornecedor,
        fornecedor: { id_fornecedor, nome: model.fornecedor_nome },
      };
      this.idTipoMaterial = this.model.id_tipo_material;
      this.oldIdTipoMaterial = this.model.id_tipo_material;
      if (model.id_item_avulso) {
        this.model.isMaterialAvulso = true;
        this.model.codeAvulso = model.itemAvulso.code;
        this.model.quantidade_itens = model.itemAvulso.quantidade_pecas;
      }
      await this.updateCentralEsterilizacao(model.id_unidade_patrimonio);
      await this.updateCamposMaterial(this.idTipoMaterial);
      this.$emit('modelUpdated', {
        lastCiclo, stringZebra, fornecedor_nome, medico_nome,
      });
    },
    campoIsEditable(campo) {
      return this.camposMaterial[campo] && this.camposMaterial[campo].editavel;
    },
    updateMaterial(campo, val) {
      this.model[campo] = val;
    },
    inputUnidade(val) {
      this.model.procedimento.id_unidade_destino = val;
    },
    instrumentoSelect(itemSelected) {
      this.model.tipoItem = itemSelected;
      this.model.descricao = itemSelected ? itemSelected.descricao : '';
      this.model.fotos = itemSelected ? itemSelected.fotoTipoItem : [];
    },
    setImage(images) {
      this.model.fotos = images;
    },
    instrumentoSelectOnLote(idItemSelected) {
      const item = this.$refs.tipoItemInox.apiResponse.find((i) => i.id_tipo_item === idItemSelected);
      this.descricao_caixas = item && item.descricao;
      this.tipoItem = item;
    },
    saveCaixa() {
      if (!+this.quantidade_caixas) {
        this.quantidade_caixas = 0;
        return;
      }
      this.model.lote.push({
        quantidade_itens: this.quantidade_itens,
        quantidade_caixas: this.quantidade_caixas,
        descricao_caixas: this.descricao_caixas,
        tipoItem: this.tipoItem,
      });
      this.$refs.modalLote.hide();
    },
    addCaixa() {
      this.$refs.modalLote.show();
    },
    openModalFoto(urls) {
      this.modalPhotos = urls || this.model.fotos;
      this.$refs.modalPhoto.show();
    },
    closeModalFoto() {
      this.modalPhotos = [];
      this.$refs.modalPhoto.hide();
    },
    async updateCamposMaterial(idTipoMaterial) {
      const route = `tipoMaterial/CampoMaterial/${idTipoMaterial}`;
      const camposMaterial = await genericRequest.getWithoutPagination({}, route);
      this.camposMaterial = camposMaterial;
      this.updateModelByTipo();
    },
    async updateCentralEsterilizacao(id_unidade) {
      if (!id_unidade) return;
      const cme = await unidadeService.getCmeByIdUnidade(id_unidade);
      this.id_setor_CME = cme.id_setor;
    },
    updateModelByTipo() {
      const newModelValues = {};
      Object.keys(this.camposMaterial).forEach((chave) => {
        const { padrao, editavel } = this.camposMaterial[chave];
        if (
          padrao
          && (!editavel || !this.editing)
        ) {
          if (padrao === 'true' || padrao === 'false') {
            newModelValues[chave] = eval(padrao); // eslint-disable-line no-eval
          } else {
            newModelValues[chave] = Number.isNaN(Number(padrao))
              ? padrao
              : Number(padrao);
          }
        }
      });
      if (Object.keys(newModelValues).length) {
        this.model = { ...this.model, ...newModelValues };
      }
    },

    async validateAll() {
      this.$validator.resume();
      const veeValidation = await this.$validator.validateAll();
      return veeValidation;
    },

    async customSubmit(submitFunc) {
      const validation = await this.validateAll();

      if (!validation) {
        swal({
          icon: 'error',
          title: 'Campos não preenchidos corretamente',
          text: 'Favor verificar todos os campos do cadastro do material antes de salvar',
          buttons: { confirm: 'Continuar' },
        });
        return;
      }
      submitFunc();
    },
    async getAvisoInfo(aviso) {
      try {
        this.model.procedimento = {
          aviso: aviso.aviso,
          paciente: aviso.paciente_iniciais,
          data_procedimento: aviso.data_cirurgia,
          procedimento_cirurgico: aviso.procedimento,
          especialidade: aviso.especialidade,
          horario_procedimento: aviso.horario_cirurgia,
          id_unidade_destino: aviso.unidade.id_unidade,
        };
      } catch (e) {
        let reqErrorMessage = 'Ocorreu um erro desconhecido, contactar suporte da InVision';
        if (
          e.response
          && e.response.data
          && e.response.data.error
        ) {
          reqErrorMessage = e.response.data.error.errorMessage;
        }
        this.model.aviso = '';
        swal({
          icon: 'error',
          title: 'Erro ao buscar dados do aviso',
          text: reqErrorMessage,
        });
      }
    },
    handleFornecedorChanged(v) {
      if (v && this.$refs.fornecedor && this.$refs.fornecedor.apiResponde) {
        this.model.fornecedor = this.$refs.fornecedor.apiResponde.find((f) => f.id_fornecedor === v);
      }
    },

    async updateTiposProcessamento(val) {
      if (this.camposMaterial.tipo_processamento || this.editing) {
        const tiposProcessamento = await genericRequest.getWithoutPagination(
          {}, `tipoMaterial/tipoProcessamento/${val}`,
        );

        this.tipoEsterilizacao = tiposProcessamento.tipoEsterilizacao.map((v) => ({
          label: v.label,
          value: v.value,
        })) || [];

        this.tipoLavagem = tiposProcessamento.tipoLavagem.map((v) => ({
          label: v.label,
          value: v.value,
        })) || [];
      }
    },
    async getPermisionsExtendedDeleteMaterials() {
      if (!this.editing) return;
      console.log(this.configuracao, this.configuracao.deletedMaterialsWithCycle);
      this.deletedMaterialsWithCycle = this.configuracao.deletedMaterialsWithCycle
        && this.configuracao.deletedMaterialsWithCycle === 'true';
      if (!this.deletedMaterialsWithCycle) {
        const idMaterial = this.model.id_material;
        this.notDeletable = Boolean((await genericRequest.getWithoutPagination(
          {},
          `ciclo/processosByCiclo/${idMaterial}`,
        )).id_ciclo);
        console.log('resposta', this.notDeletable);
      }
    },
  },
};
</script>

<style>
.button-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
</style>
